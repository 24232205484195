<template>
    <div ref="container" class="position-relative">
                     <div class="w-100 multiFilter px-2">
                       <div
                         class="d-flex align-items-center justify-content-between"
                       >
                         <input
                             :style="[{borderRadius:'8px',border:'1px solid '+pro.style.color}]"
                           ref="searchInput"
                           class="filterSearch w-100"
                           type="text"
                           v-model="searchQuery"
                           :placeholder=placeholder
                           @focus="onSearchboxFocus"
                           @blur="onblurHandle"
                           @input="handlePostalAddress()"
                         >
                       </div>
                     </div>
                     <ul class="popover-list cursor-pointer" v-if="listActive">
                     <template v-if="isLoadingPostalAddress">
                       <div class="d-flex justify-content-center align-items-center">
                         <b-spinner small class="mx-2"></b-spinner> Loading...
                       </div>
                     </template>
                       <template v-else-if="postalAddress.length > 0">
                         <li
                           @click="selectedOptionHandle(item)"
                           class="popover-item"
                           v-for="(item, index) in postalAddress"
                           :key="index"
                         >
                           <div class="d-flex align-items-center">
                             <div
                               class="rounded p-1"
                               style="background-color: rgba(251, 190, 113, 0.2)"
                             >
                               
                             </div>
                             <div class="px-2">{{ item.city+', '+item.stateCode+' - '+item.postalCode }}</div>
                           </div>
                         </li>
                       </template>
                       <template v-else>
                         <div class="d-flex align-items-center justify-content-center">
                           No Origin Found.
                         </div>
                       </template>
                     </ul>
         </div>
 </template>
 <script>
 import { debounce } from "lodash";
   export default {
   props:{
       pro:Object,
       placeholder:String,
       index:Number
   },
   data(){
       return {
         isFocused:false,
           isInputFocused:-1,
           options:[...this.pro.options],
           selectedOption:{},
           listActive:false,
           step1Text:'',
           searchQuery:'',
           postalAddress:[],
           isLoadingPostalAddress:false
       }
   },
   computed:{
       style(){
           return this.pro.style
       },
   },
   watch:{

       options:{
           handler(newValue){
               let checkAllFields=true;
               for(let key in newValue)
               {
                
                  if(newValue[key].value=='')
                  { 
                    
                   checkAllFields=false;
                   break;
                  }
                   
               }
             
               if(checkAllFields)
               this.$emit('selectedOptions',newValue)
               else
               this.$emit('selectedOptions',[])
           },
           deep: true,
           intermediate:true,
       },
      
   },
   methods:{
    
   selectedOptionHandle(item){
   this.listActive=false;
   this.selectedOption=item;
     this.$emit('change',item)
   this.searchQuery = item.city+', '+item.stateCode+' - '+item.postalCode;
   },
     handlePostalAddress:debounce(function () {
       this.fetchData();
     }, 500),
     async fetchData() {
     const corsAnywhereUrl = 'https://api.allorigins.win/get';
     const apiUrl = `https://customer-api.chrobinson.com/sb-quoting/v1/Guest/Locations/Suggestions?searchQuery=${this.searchQuery}`;
     const urlWithQuery = `${corsAnywhereUrl}?url=${encodeURIComponent(apiUrl)}`;
     this.isLoadingPostalAddress=true;
    await fetch(urlWithQuery)
                     .then(response => {
                       if (response.ok) return response.json()
                       throw new Error('Network response was not ok.')
                     })
                     .then(data => {
                      this.postalAddress = this.searchQuery!=''?JSON.parse(data.contents).results:[];
                     });
   this.isLoadingPostalAddress=false;
 },
 // Usage example
 onblurHandle(){
   this.$refs.searchInput.style.border='1px solid '+this.pro.style.color;
 },
     onSearchboxFocus() {
       this.listActive = true;
       this.$refs.searchInput.style.border='1px solid '+this.pro.style.selectedCheckboxColor;
       this.$refs.searchInput.style.outline='1px solid transparent';
       document.addEventListener("click", this.onDocumentClick);
     },
     onSearchboxBlur() {
       // If you want to close the list when the searchbox loses focus, uncomment the next line
       // this.listActive = false;
     },
     onDocumentClick(event) {
       if (this.$refs.container)
         if (!this.$refs.container.contains(event.target)) {
           this.listActive = false;
           // this.$refs.searchInput.blur();
         }
     },
       userInputControlStye(index){
           const {borderRadius,color,fontSize,fontWeight,selectedTab} = this.style
           return {'border-radius':borderRadius+'px',color:color,fontSize:fontSize+'px',fontWeight:fontWeight, borderColor:this.isInputFocused==index?selectedTab.backgroundColor+'!important':''}
       },
        circleStyle(index){
           const {selectedTab} = this.style
           const checkIndex = this.isInputFocused==index
           return { background:checkIndex?selectedTab.backgroundColor+'!important':'#F3F1EB',color:checkIndex?selectedTab.textColor+'!important':'inherit'}
       },
       
   }
   }
   </script>
   
 <style>
 .rectangle-list span:hover {
   background: #eee;
 }
 .rectangle-list .active:before {
   background: #4d1b7e !important;
   color: #fff;
 }
 .rectangle-list span:before {
   content: counter(li);
   counter-increment: li;
   position: absolute;
   left: -2.5em;
   top: 50%;
   margin-top: -1em;
   background: #eee;
   height: 2em;
   width: 2em;
   color: #0c0c0c;
   line-height: 2em;
   text-align: center;
   font-size: 14px;
 }
 .rectangle-list span:after {
   position: absolute;
   content: "";
   border: 0.5em solid transparent;
   left: -1em;
   top: 50%;
   margin-top: -0.5em;
   transition: all 0.3s ease-out;
 }
 .rectangle-list span:hover:after {
   left: -0.5em;
   border-left-color: #ccc;
 }
 .rectangle-list span.active:after {
   left: -0.5em;
   border-left-color: #4d1b7e;
 }
 .suggestion-list {
   position: absolute;
   top: 100%;
   left: 0;
   width: 100%;
   max-height: 150px; /* Adjust max-height as needed */
   overflow-y: auto;
   background-color: #fff;
   border: 1px solid #ccc;
   border-top: none;
   border-radius: 0 0 4px 4px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 0;
   margin: 0;
   list-style-type: none;
 }
 .suggestion-item {
   padding: 8px;
   cursor: pointer;
 }
 .suggestion-item:hover {
   background-color: #f0f0f0;
 }
 .custom-textarea {
   width: 100%;
   padding: 8px;
   border: 1px solid #ccc;
   border-radius: 4px;
   font-size: 14px;
 }
 .cursor-pointer {
   cursor: pointer !important;
 }
 /* Add your custom CSS styling here */
 .popover-list {
   list-style-type: none;
   padding: 0;
   position: absolute;
   z-index: 11111111111;
   width: 100%;
   background: white;
   border: 1px solid #ccc;
   border-radius: 0.25rem;
   max-height: 250px;
   min-height: 50px;
   overflow-y: scroll;
 }
 .popover-item {
   padding: 8px;
 }
 .popover-item:hover {
   background-color: #f0f0f0;
 }
 .filterSearch {
   padding: 8px;
   /* border: 1px solid #ccc; */
   border: none;
   border-radius: 4px;
   margin-bottom: 8px;
   margin: 0px;
 }
 </style>
